import React, {
  useContext, useState, useEffect, useRef, useMemo, useCallback,
} from 'react';
import { CollectionEventsContext, CollectionSelectionsContext } from '../contexts';
import Download from '#/shared/clientReporting/Icons/Download';
import SmallButton from '#/shared/clientReporting/web-platform-components/Buttons/SmallButton';
import { CSVLink } from 'react-csv';
import { Tab } from '../constants';
import { Metrics } from '#/types/MapRollup';
import { RollupEventPartition, RollupGroups } from '#/types/ReportingRollup';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { CumulativeMetricKey, TimeMeasurement } from '../utils/menuOptions';

interface Props {
  data?: any[];
  isLoading?: boolean;
}

interface CollectionsCSV extends Metrics, RollupGroups {
  [key: string]: string | number | boolean | undefined | DateTime<boolean> | RollupEventPartition;
}

const DEFAULT_CSV_GROUP_NAME = 'event_collection';

const CUMULATIVE_TO_BASE_PROPERTY_MAP: Record<CumulativeMetricKey, string> = {
  cumulativeCost: 'cost',
  cumulativeTicketsSold: 'ticketsSold',
  cumulativeManagedInventory: 'managedInventory',
  cumulativeRevenue: 'revenue',
  cumulativeIngestedFaceValue: 'totalFaceValue',
  cumulativeSoldFaceValue: 'soldFaceValue',
  cumulativeTake: 'take',
  cumulativeTakeAttr: 'takeAttr',
  cumulativeLiftOfSold: 'liftOfSold',
  cumulativeAverageTicketPrice: 'averageTicketPrice',
  cumulativeLiftOfSoldPercent: 'liftOfSoldPercent',
  cumulativeTicketsUnsoldPercent: 'cumulativeTicketsUnsold',
};

const getBaseProperty = (cumulativeProp: string): string => {
  return CUMULATIVE_TO_BASE_PROPERTY_MAP[
    cumulativeProp as keyof typeof CUMULATIVE_TO_BASE_PROPERTY_MAP
  ] ?? cumulativeProp;
};

const ExportCSV: React.FC<Props> = ({ isLoading, data }) => {
  const {
    selectedGroup, metricSelection, activeTab, timeGroup,
  } = useContext(CollectionSelectionsContext);
  const { selectedEventIds } = useContext(CollectionEventsContext);

  const [csvTitle, setCSVTitle] = useState<string>('');
  const csvLink = useRef(null);

  useEffect(() => {
    const dateNow = (new Date()).toISOString().split('.')[0];
    const tabName = activeTab.toLowerCase().replace(' ', '_');
    const groupName = selectedGroup?.name ?? DEFAULT_CSV_GROUP_NAME;

    setCSVTitle(`${dateNow}_${groupName}_${tabName}.csv`);
  }, [selectedGroup, activeTab]);

  const baseProperty = useMemo(() => {
    return getBaseProperty(metricSelection.key);
  }, [metricSelection.key]);

  const HEADER_MAP = useMemo(() => (
    {
      timeValue: timeGroup.key === TimeMeasurement.TIME_TO_EVENT && activeTab === Tab.TRENDS
        ? 'logHoursToEvent'
        : 'time',
      label: 'section',
      isPreview: 'isPreview',
      autobrokerEventId: 'autobrokerEventId',
      eventTitle: 'eventTitle',
      eventStartsAt: 'eventStartsAt',
      [baseProperty]: baseProperty,
      [metricSelection.key]: activeTab === Tab.TRENDS
        ? metricSelection.key
        : `${metricSelection.key.replace('cumulative', '')}`,
    }
  ), [baseProperty, activeTab, timeGroup, metricSelection.key]);

  const X_HEADER = useMemo(() => {
    switch (activeTab) {
      case Tab.TRENDS:
      case Tab.TIME_BINS:
        return 'timeValue';
      case Tab.MAP:
        return 'label';
      default:
        return '';
    }
  }, [activeTab]);

  const Y_HEADERS = useMemo(() => {
    switch (activeTab) {
      case Tab.TRENDS:
        return [
          'isPreview',
          metricSelection.key,
        ];
      case Tab.TIME_BINS:
        return [
          baseProperty,
        ];
      case Tab.MAP:
        return [
          metricSelection.key,
        ];
      default:
        return [];
    }
  }, [activeTab, metricSelection.key, baseProperty]);

  const GROUP_HEADERS = useMemo(() => {
    switch (activeTab) {
      case Tab.TRENDS:
      case Tab.TIME_BINS:
        return [
          'autobrokerEventId',
          'eventTitle',
          'eventStartsAt',
        ];
      default:
        return [];
    }
  }, [activeTab]);

  const csvHeaders = useMemo(() => {
    return [
      ...GROUP_HEADERS.map((g: string) => (HEADER_MAP[g])),
      HEADER_MAP[X_HEADER],
      ...Y_HEADERS.map((y: string) => (HEADER_MAP[y])),
    ];
  }, [HEADER_MAP, GROUP_HEADERS, X_HEADER, Y_HEADERS]);

  const filteredData = useMemo(() => {
    if (activeTab === Tab.MAP) {
      const nonEmptyData = data?.filter((d: any) => (d.metrics[metricSelection.key]));

      return nonEmptyData?.reduce((acc, curr, idx: number) => {
        if (idx === 0) {
          acc.push(curr);
          return acc;
        }
        if (curr.label !== nonEmptyData[idx - 1]?.label)
          acc.push(curr);

        return acc;
      }, []);
    }
    return data;
  }, [data, activeTab, metricSelection.key]);

  const csvData = useMemo(() => {
    return filteredData?.map((d: any) => {
      const result : CollectionsCSV = {};

      GROUP_HEADERS.forEach((header: string) => {
        result[HEADER_MAP[header]] = d.autobrokerEvent[header]?.isLuxonDateTime
          ? d.autobrokerEvent[header].toISO()
          : d.autobrokerEvent[header];
      });

      if (X_HEADER !== '')
        result[HEADER_MAP[X_HEADER]] = typeof d[X_HEADER] === 'number' ? d[X_HEADER].toFixed(2) : d[X_HEADER];

      Y_HEADERS.forEach((header: string) => {
        if (activeTab === Tab.MAP)
          result[HEADER_MAP[header]] = typeof d.metrics[header] === 'number' ? d.metrics[header].toFixed(3) : d.metrics[header];
        else
          result[HEADER_MAP[header]] = typeof d[header] === 'number' ? d[header].toFixed(3) : d[header];
      });

      return result;
    }) ?? [];
  }, [filteredData, HEADER_MAP, activeTab, X_HEADER, Y_HEADERS, GROUP_HEADERS]);

  const downloadCSV = useCallback(() => {
    csvLink.current.link.click();
  }, []);

  const isDisabled = useMemo(() => {
    if (activeTab === Tab.MAP)
      return selectedEventIds?.length === 0;
    if (activeTab === Tab.TIME_BINS) {
      return csvData?.every(
        (d: any) => (
          d[HEADER_MAP[baseProperty]] === undefined || Number(d[HEADER_MAP[baseProperty]]) === 0
        ),
      );
    }
    if (activeTab === Tab.TRENDS)
      return csvData.length === 0;
    return false;
  }, [csvData, selectedEventIds, baseProperty, activeTab, HEADER_MAP]);

  return (
    <Container isLoading={isLoading}>
      <SmallButton
        Icon={<Download />}
        isDisabled={isDisabled}
        isLoading={isLoading}
        kind='secondary'
        label="Export"
        onClick={downloadCSV}
      />
      <CSVLink data={csvData} filename={csvTitle} headers={csvHeaders} ref={csvLink} />
    </Container>
  );
};

interface LoadingProps {
  isLoading?: boolean;
}

const Container = styled.div<LoadingProps>`
  border-right: 1px solid #ddd;
  opacity: ${({ isLoading }): number => isLoading ? 0.4 : 1};
  padding-left: 8px;
  padding-right: 8px;
`;

export default ExportCSV;
