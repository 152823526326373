import React, {
  type CSSProperties, useMemo, useCallback, useContext, useState,
} from 'react';
import styled from 'styled-components';
import { min, max } from 'd3-array';
import {
  GG, Labels, ScaleStroke, ScaleStrokeDasharray, ScaleX, ScaleY,
} from '@graphique/graphique';
import { GeomLine, Legend } from '@graphique/geom-line';
import { GeomHLine } from '@graphique/geom-hline';
import { Theme } from '#/shared/graphique';
import palette from '#/theme/palettes/main';
import Tooltip from './Tooltip';
import {
  TimeMeasurement,
  ZERO_BASELINE_METRICS,
  PREVIEWABLE_METRICS,
} from '../utils/menuOptions';
import { type EventTimeSeriesSalesSummary } from '../utils/getSalesTrendsByEvent';
import { DateTime } from 'luxon';
import Loader from '#/shared/Loader';
import { CollectionLayoutContext, CollectionSalesDataContext, CollectionSelectionsContext } from '../contexts';
import MissingData from '../utils/MissingData';
import { LoadingWrapper } from '../PageComponents';
import { Control, ControlBar, TimeViewToggle } from '../Controls';

interface OverlaidSalesChartProps {
  data: EventTimeSeriesSalesSummary[]
  isLoading: boolean
  hasPreviewOverridesLoading: boolean
  focusedDatum?: EventTimeSeriesSalesSummary[]
  onChartFocus?: (datum: EventTimeSeriesSalesSummary[]) => void
  onChartUnfocus?: () => void
  onLineSelection: (id: number) => void
}

const DASH_ARRAYS = ['0', '5,2'];
const LINE_COLORS = [palette.brand.dark, palette.blue.base];
const LEGEND_STYLES: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '0.5rem',
  paddingRight: '2rem',
  fontSize: '0.78rem',
};

enum Trends {
  ACTUAL = 'Actual',
  TARGET = 'Target',
}

const focusGroupAccessor = (d: EventTimeSeriesSalesSummary): number => (
  d.autobrokerEvent.autobrokerEventId
);

const OverlaidSalesChart: React.FC<OverlaidSalesChartProps> = ({
  data = [], hasPreviewOverridesLoading, isLoading,
  focusedDatum, onChartFocus, onChartUnfocus, onLineSelection,
}) => {
  const [includedGroups, setIncludedGroups] = useState(Object.values(Trends));

  const { isWide } = useContext(CollectionLayoutContext);
  const { setHasOverlaidSalesTarget } = useContext(CollectionSalesDataContext);
  const {
    metricSelection,
    timeGroup,
    handleTimeSelection,
  } = useContext(CollectionSelectionsContext);

  const asTimeToEvent = timeGroup.key === TimeMeasurement.TIME_TO_EVENT;
  const possiblyNegative = ZERO_BASELINE_METRICS.includes(metricSelection.key);
  const ZERO_Y = useMemo(() => (
    [{ [metricSelection.key]: 0 }]
  ), [metricSelection]);

  const isPreviewable = useMemo(() => (
    PREVIEWABLE_METRICS.includes(metricSelection.key)
  ), [metricSelection.key]);

  const hasExplicitDomain = useMemo(() => (
    !isPreviewable || (isPreviewable && includedGroups.length === Object.values(Trends).length)
  ), [isPreviewable, includedGroups]);

  const xDomain = useMemo(() => {
    const timeValues = data.map((d) => d.timeValue);

    return hasExplicitDomain ? (
      [
        min(asTimeToEvent ? [0, ...timeValues] : timeValues),
        max(timeValues),
      ]
    ) : undefined;
  }, [data, asTimeToEvent, hasExplicitDomain]);

  const yMin = useMemo(() => (
    min([0, ...data.map((d) => d[metricSelection.key])])
  ), [data, metricSelection]);
  const yDomain = useMemo(() => (
    ZERO_BASELINE_METRICS.includes(metricSelection.key) && hasExplicitDomain
      ? [
        yMin,
        max([0, ...data.map((d) => d[metricSelection.key])]),
      ]
      : undefined
  ), [metricSelection, data, yMin, hasExplicitDomain]);

  const definedMeasurements = (
    data?.filter((d) => (
      typeof d[metricSelection.key] !== 'undefined' && !Number.isNaN(d[metricSelection.key])
      // exclude preview measurements for past events
      // (especially the "connection point" between preview/actual measurements)
      && (d.isPreview ? d.autobrokerEvent.eventStartsAt > DateTime.utc() : true)
    ))
  );

  const showLegend = new Set(definedMeasurements.map((m) => m.isPreview)).size > 1;

  const numSelectedEvents = new Set(definedMeasurements.map((d) => (
    d.autobrokerEvent.autobrokerEventId
  ))).size;

  const hasSelectedData = numSelectedEvents > 0;
  const opacityStyles = {
    opacity: numSelectedEvents === 1 ? 1 : Math.min(8 / numSelectedEvents, 0.2),
  };

  // scroll to event in collection list when clicking focused trend line
  const handleSelection = useCallback((d: EventTimeSeriesSalesSummary[]) => {
    const id = d?.[0]?.autobrokerEvent?.autobrokerEventId;

    if (id)
      onLineSelection(id);
  }, [onLineSelection]);

  const handleLegendSelection = useCallback((group: Trends) => {
    setIncludedGroups((prev) => {
      const isOnlyGroup = prev.length === 1 && prev.includes(group);

      setHasOverlaidSalesTarget(
        group === Trends.ACTUAL
        || (group === Trends.TARGET && !prev.includes(group))
        || (group === Trends.TARGET && isOnlyGroup),
      );

      const filteredGroups = (
        prev.includes(group)
          ? prev.filter((g) => g !== group)
          : [...prev, group]
      );

      return isOnlyGroup ? Object.values(Trends) : filteredGroups;
    });
  }, [setHasOverlaidSalesTarget]);

  // include data for only legend-included groups
  const includedData = useMemo(() => (
    isPreviewable
      ? definedMeasurements
        ?.filter((d) => includedGroups.includes(d.isPreview ? Trends.TARGET : Trends.ACTUAL))
      : definedMeasurements
  ), [definedMeasurements, includedGroups, isPreviewable]);

  const formatYTicks = useCallback(({ value }: { value: number }) => (
    metricSelection.format({ value, showDecimal: false })
  ), [metricSelection]);

  return (
    <>
      <ControlBar
        data={includedData}
        isLoading={isLoading || hasPreviewOverridesLoading}
      >
        <Control>
          <TimeViewToggle
            isLoading={isLoading}
            onChange={handleTimeSelection}
            selectedValue={timeGroup.key}
          />
        </Control>
      </ControlBar>

      {isLoading && (
        <LoadingWrapper>
          <Loader
            data-testid='event-collection-rollup-loader'
            size={3.5}
            thickness={0.3}
          />
        </LoadingWrapper>
      )}

      {!isLoading && (
        <Wrapper>
          {hasSelectedData ? (
            <GG
              aes={{
                x: (d): number | Date => d?.timeValue,
                y: (d): number => d[metricSelection.key],
                stroke: (d): string => d.isPreview ? Trends.TARGET : Trends.ACTUAL,
                strokeDasharray: (d): string => d.isPreview ? Trends.TARGET : Trends.ACTUAL,
                group: (d): string => (
                  `${String(d?.autobrokerEvent?.autobrokerEventId)}-${String(d.isPreview)}`
                ),
              }}
              data={includedData}
              height={590}
              isContainerWidth
              margin={{ top: 20, left: 80, right: 80 }}
            >
              {possiblyNegative && (Math.round(yMin * 100) / 100) && (
                <GeomHLine
                  data={ZERO_Y}
                  showTooltip={false}
                  stroke='#bbb'
                  strokeDasharray='4,2'
                />
              )}
              <GeomLine
                entrance='data'
                focusGroupAccessor={focusGroupAccessor}
                focusType='closest'
                focusedStyle={{ opacity: 1 }}
                markerRadius={2.2}
                onDatumFocus={onChartFocus}
                onDatumSelection={handleSelection}
                onExit={onChartUnfocus}
                strokeWidth={1.8}
                style={opacityStyles}
                unfocusedStyle={{
                  opacity: 0.5,
                }}
              />
              <ScaleX
                domain={xDomain}
                format={timeGroup.format}
                numTicks={isWide ? 5 : 2}
                reverse={asTimeToEvent}
              />
              <ScaleY
                domain={yDomain}
                format={formatYTicks}
                numTicks={5}
              />
              <ScaleStrokeDasharray
                domain={Object.values(Trends)}
                values={DASH_ARRAYS}
              />
              <ScaleStroke
                domain={Object.values(Trends)}
                values={LINE_COLORS}
              />
              <Tooltip
                focusedDatum={focusedDatum}
                metricSelection={metricSelection}
                timeGroup={timeGroup}
              />
              <Labels
                x={timeGroup.key === TimeMeasurement.TIME_TO_EVENT ? timeGroup.label : undefined}
              />
              {showLegend && (
                <Legend
                  onSelection={handleLegendSelection}
                  orientation='horizontal'
                  style={{
                    ...LEGEND_STYLES,
                    opacity: hasPreviewOverridesLoading ? 0.4 : undefined,
                  }}
                />
              )}
              <Theme axis={{ showAxisLines: false }} />
              {hasPreviewOverridesLoading && (
                <LoadingOverlay>
                  <Loader
                    size={3.5}
                    thickness={0.3}
                  />
                </LoadingOverlay>
              )}
            </GG>
          )
            : <MissingData />}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 2.75rem;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F5F4;
  opacity: 0.6;
  width: 100%;
  height: 640px;
`;

export default OverlaidSalesChart;
export { type OverlaidSalesChartProps };
