import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import TableWrapper, {
  Cell,
  NoWrapCell,
  SmallCell,
  Header,
  ClickableRowWrapper,
  RowWrapper,
} from '#/shared/TableComponents';
import Loader from '#/shared/Loader';
import Error from '#/shared/Error';
import palette from '#/theme/palettes/main';
import { UsePriceLevelsHook } from './useFetchPriceLevels';
import { UsePriceListHook } from './useFetchPriceList';
import PriceLevel from '#/types/PriceLevel';
import { formatGtv, formatNumber } from '#/shared/formatNumberForDisplay';
import PriceLevelIcons from './PriceLevelIcons';
import PageButton from '#/shared/Button';
import { Button } from '#/shared/Detail';
import Edit from '#images/Edit.svg';
import DownArrow from '#images/DownArrow.svg';
import UpArrow from '#images/UpArrow.svg';
import UpDownArrow from '#images/UpDownArrow.svg';
import { StakeholderLogos } from '#/pages/useFetchStakeholders';

const ERROR_COPY = 'Something went wrong retrieving listings!';
const PREVIOUS_PAGE = 'Previous Page';
const NEXT_PAGE = 'Next Page';
const PRICE_GROUP = 'Price';

interface Column {
  label: string;
  colSpan: number;
  group?: 'Price';
  keyFunc?: (l: PriceLevel) => number | string;
}

const columns: Column[] = [
  { label: 'ID', colSpan: 1, keyFunc: (l: PriceLevel): number | string => l.id },
  { label: 'Source ID', colSpan: 1, keyFunc: (l: PriceLevel): string => JSON.stringify(l.level) },
  { label: 'Name', colSpan: 1, keyFunc: (l: PriceLevel): string => l.name },
  { label: 'Sections', colSpan: 1, keyFunc: (l: PriceLevel): string => l.summary.sections.join(' ') },
  { label: 'Listings', colSpan: 1, keyFunc: (l: PriceLevel): number => l.summary.listingCt || 0 },
  { label: 'Tickets', colSpan: 1, keyFunc: (l: PriceLevel): number => l.summary.ticketCt || 0 },
  { label: 'Face', colSpan: 1, keyFunc: (l: PriceLevel): number => l.faceValue?.amount },
  {
    label: 'Price', colSpan: 1, group: PRICE_GROUP, keyFunc: (l: PriceLevel): number => l.price.amount,
  },
  {
    label: 'EV', colSpan: 1, group: PRICE_GROUP, keyFunc: (l: PriceLevel): number => l.price.logExpectedValue,
  },
  {
    label: 'LPR', colSpan: 1, group: PRICE_GROUP, keyFunc: (l: PriceLevel): number => l.price.logPriceRatio,
  },
  { label: 'Constraint', colSpan: 1, keyFunc: (l: PriceLevel): number => l.override?.priceConstraint?.priceFloor || l.override?.priceConstraint?.priceCeiling || 0 },
  { label: 'Stakeholder', colSpan: 1, keyFunc: (l: PriceLevel): string => l.summary.stakeholders.join(' ') },
  { label: 'Properties', colSpan: 1 },
  { label: 'Edit', colSpan: 1 },
];

interface PriceLevelSort {
  field: string;
  keyFunc: (l: PriceLevel) => string | number;
  asc: boolean;
}

interface PriceLevelsTableProps {
  selectedIds: (number | string)[];
  setSelected: React.Dispatch<React.SetStateAction<PriceLevel[]>>;
  priceLevelsHook: UsePriceLevelsHook;
  priceListHook: UsePriceListHook;
  setOverridePriceLevelModal: (priceLevels: PriceLevel[]) => void;
  canEdit: boolean;
  showPrices: boolean;
  stakeholderLogos: StakeholderLogos;
}

const PriceLevelsTable: React.FC<PriceLevelsTableProps> = ({
  selectedIds,
  setSelected,
  priceLevelsHook,
  priceListHook,
  setOverridePriceLevelModal,
  canEdit,
  showPrices,
  stakeholderLogos,
}) => {
  const [sort, setSort] = React.useState<PriceLevelSort>(undefined);

  const {
    priceLevels, filters, retry, pages, prevPage, nextPage, showResults, showError, showLoader,
  } = priceLevelsHook;
  const { priceList } = priceListHook;

  const priceLevelsCombined = useMemo(() => (
    priceLevels.map((level) => {
      const entry = priceList.find((l) => JSON.stringify(l.level) === JSON.stringify(level.level));

      return { ...level, faceValue: entry?.price, name: entry?.name ?? level.name };
    })
  ), [priceLevels, priceList]);
  const priceListRemainder = useMemo(() => (
    priceList
      .filter((l) => (
        !priceLevels.find(
          (level) => JSON.stringify(l.level) === JSON.stringify(level.level),
        )
      ))
  ), [priceLevels, priceList]);

  const allPriceLevels = useMemo(() => {
    const levels = [...priceLevelsCombined, ...priceListRemainder];

    return levels.sort((a, b) => {
      if (!sort)
        return 0;
      const aVal = sort.keyFunc(a);
      const bVal = sort.keyFunc(b);

      if (aVal > bVal)
        return sort.asc ? 1 : -1;
      if (aVal < bVal)
        return sort.asc ? -1 : 1;
      return 0;
    });
  }, [priceLevelsCombined, priceListRemainder, sort]);

  const onClick = useCallback((event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = (event.currentTarget.children[0] as HTMLTableCellElement).innerText;
    const selected = allPriceLevels.find((level) => String(level.id) === id);

    if (selected) {
      // support for determining which rows to add as selected, supporting conventional
      // multi-row-select behavior.
      if (event.ctrlKey || event.metaKey) {
        const alreadySelected = selectedIds.includes(id);

        if (!alreadySelected)
          setSelected((s) => [...s, selected]);
      } else if (event.shiftKey) {
        const lastSelected = selectedIds[selectedIds.length - 1];

        if (lastSelected) {
          const lastIdx = allPriceLevels.findIndex(
            (level) => level.id === lastSelected,
          );
          const curIdx = allPriceLevels.findIndex((level) => String(level.id) === id);
          const start = lastIdx < curIdx ? lastIdx : curIdx;
          const end = lastIdx < curIdx ? curIdx : lastIdx;

          const newSelected = allPriceLevels
            .filter((level: PriceLevel, idx: number): boolean => {
              return (
                idx >= start
                && idx <= end
                && !selectedIds.includes(level.id)
              );
            });

          if (newSelected.length > 0)
            setSelected((s) => [...s, ...newSelected]);
        }
      } else {
        setSelected([selected]);
      }
    }
  }, [setSelected, allPriceLevels, selectedIds]);

  const filteredColumns = useMemo(() => (
    columns.filter((column) => (
      (column.label === 'Edit' ? canEdit : true)
      && (column.group === PRICE_GROUP ? showPrices : true)
    ))), [canEdit, showPrices]);

  const switchOrder = useCallback(() => {
    setSort((c) => ({ ...c, asc: !c.asc }));
  }, [setSort]);

  const setSortByColumn = useCallback((column: Column) => {
    const newSort = (
      column.keyFunc
        ? { field: column.label, keyFunc: column.keyFunc, asc: true }
        : undefined
    );

    setSort(newSort);
  }, [setSort]);

  const headers = useMemo(() => {
    return filteredColumns.map((column) => {
      if (column.keyFunc && column.label === sort?.field) {
        return (
          <NoWrapCell key={`sort_cell_${column.label}`} onClick={switchOrder} style={{ cursor: 'pointer' }}>
            <OffsetImage
              alt="sort"
              height="13"
              src={sort?.asc ? UpArrow : DownArrow}
              title={sort?.asc ? 'Ascending' : 'Descending'}
            />
            {column.label}
          </NoWrapCell>
        );
      }
      if (column.keyFunc && column.label !== sort?.field) {
        return (
          <Cell
            colSpan={column.colSpan}
            key={column.label}
            // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
            onClick={(): void => (setSortByColumn(column))}
            style={{ cursor: column.keyFunc ? 'pointer' : null }}
          >
            <OffsetImage
              alt="sort"
              height="13"
              src={UpDownArrow}
              style={{ opacity: 0.4 }}
              title="Sortable"
            />
            {column.label}
          </Cell>
        );
      }
      return (
        <Cell
          colSpan={column.colSpan}
          key={column.label}
          // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
          onClick={(): void => (setSortByColumn(column))}
          style={{ cursor: column.keyFunc ? 'pointer' : null }}
        >
          {column.label}
        </Cell>
      );
    });
  }, [filteredColumns, sort, setSortByColumn, switchOrder]);

  return (
    <>
      <TableWrapper>
        <thead>
          <Header>
            {headers}
          </Header>
        </thead>
        {showResults && (
          <tbody>
            {pages.length > 0 && (
              <RowWrapper key="load_previous_rows">
                <td colSpan={filteredColumns.map((column) => column.colSpan)
                  .reduce((a, b) => a + b)}
                >
                  <ButtonWrapper>
                    <PageButton onClick={prevPage}>{PREVIOUS_PAGE}</PageButton>
                  </ButtonWrapper>
                </td>
              </RowWrapper>
            )}
            {allPriceLevels.map((level) => (
              <PriceLevelTableRow
                canEdit={canEdit}
                isInactive={!level.updatedAt}
                isSelected={selectedIds.includes(level.id)}
                key={level.id}
                onClick={onClick}
                priceLevel={level}
                setOverridePriceLevelModal={setOverridePriceLevelModal}
                showPrices={showPrices}
                stakeholderLogos={stakeholderLogos}
              />
            ))}
            {priceLevels.length === filters.perPage && (
              <RowWrapper key="load_next_rows">
                <td colSpan={filteredColumns.map((column) => column.colSpan)
                  .reduce((a, b) => a + b)}
                >
                  <ButtonWrapper>
                    <PageButton onClick={nextPage}>{NEXT_PAGE}</PageButton>
                  </ButtonWrapper>
                </td>
              </RowWrapper>
            )}
          </tbody>
        )}
      </TableWrapper>
      {showError && (
        <CenterContainer>
          <Error copy={ERROR_COPY} retry={retry} />
        </CenterContainer>
      )}
      {showLoader && (
        <CenterContainer>
          <Loader hexColor={palette.brand.base} />
        </CenterContainer>
      )}
    </>
  );
};

interface PriceLevelTableRowProps {
  priceLevel: PriceLevel;
  isSelected: boolean;
  isInactive: boolean;
  onClick: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  setOverridePriceLevelModal: (priceLevels: PriceLevel[]) => void;
  canEdit: boolean;
  showPrices: boolean;
  stakeholderLogos: StakeholderLogos;
}

const PriceLevelTableRow: React.FC<PriceLevelTableRowProps> = ({
  priceLevel,
  isSelected,
  isInactive,
  onClick,
  setOverridePriceLevelModal,
  canEdit,
  showPrices,
  stakeholderLogos,
}) => {
  const {
    id,
    name,
    level,
    price,
    faceValue,
    override,
    summary,
  } = priceLevel;

  let backgroundColor = null;

  if (isSelected)
    backgroundColor = palette.brand.light24;
  else if (isInactive)
    backgroundColor = palette.gold.light24;

  let constraint = '';

  if (override?.priceConstraint) {
    const { priceFloor, priceCeiling, priceConstraintType } = override.priceConstraint;

    constraint = (
      [
        priceFloor ? `${formatGtv(priceFloor)} floor` : null,
        priceCeiling ? `${formatGtv(priceCeiling)} ceiling` : null,
      ]
        .filter((c) => c !== null)
        .join(', ') + `\n${priceConstraintType.split('_').join(' ')}`
    );
  }

  const stakeholderImages = summary.stakeholders
    ?.map((stakeholder) => stakeholderLogos[stakeholder])
    .filter((logo) => logo) || [];

  const openOverrideModal = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    // stop the row onClick from being called
    event.stopPropagation();
    setOverridePriceLevelModal([priceLevel]);
  }, [setOverridePriceLevelModal, priceLevel]);

  return (
    <ClickableRowWrapper color={backgroundColor} key={`${id}`} onClick={onClick}>
      <SmallCell key={`id_${id}`}>{id}</SmallCell>
      <Cell key={`level_${id}`} style={{ whiteSpace: 'pre', maxWidth: '7rem' }}>
        {`${level.sourceName} event ${level.sourceExternalEventId}\n${level.sourcePriceLevelId}`}
      </Cell>
      <Cell key={`name_${id}`} style={{ whiteSpace: 'pre', maxWidth: '5rem' }}>
        {name}
      </Cell>
      <SmallCell key={`sections_${id}`}>{summary.sections.join(', ')}</SmallCell>
      <SmallCell key={`listings_${id}`}>{summary.listingCt}</SmallCell>
      <SmallCell key={`tickets_${id}`}>{summary.ticketCt}</SmallCell>
      <Cell key={`face_${id}`}>{faceValue ? formatGtv(faceValue?.amount) : ''}</Cell>
      { showPrices && (
        <>
          <Cell key={`price_${id}`}>{formatGtv(price.amount)}</Cell>
          <Cell key={`ev_${id}`}>{price.logExpectedValue ? formatGtv(Math.exp(price.logExpectedValue)) : ''}</Cell>
          <Cell key={`lpr_${id}`}>{price.logPriceRatio ? formatNumber(price.logPriceRatio) : ''}</Cell>
        </>
      )}
      <Cell key={`constraint_${id}`} style={{ whiteSpace: 'pre' }} title={constraint}>
        {constraint}
      </Cell>
      <Cell key={`stakeholder_${id}`} style={{ whiteSpace: 'pre', maxWidth: '8rem' }}>
        {summary.stakeholders.join('\n')}
      </Cell>
      <NoWrapCell key={`properties_${id}`}>
        <PriceLevelIcons
          level={level}
          override={override}
          stakeholderImages={stakeholderImages}
          summary={summary}
        />
      </NoWrapCell>
      { canEdit && (
        <SmallCell>
          <Button onClick={openOverrideModal} title="edit price level">
            <EditImg />
          </Button>
        </SmallCell>
      )}
    </ClickableRowWrapper>
  );
};

const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EditImg = styled.img`
  content: url(${Edit});
  height: 20px;
`;

const ButtonWrapper = styled.div`
  text-align:center;
  width:95%;
`;

const OffsetImage = styled.img`
  padding-right: 2px;
`;

export default PriceLevelsTable;
