import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import TableWrapper, {
  Cell,
  NoWrapCell,
  SmallCell,
  Header,
  RowWrapper,
  ClickableRowWrapper,
} from '#/shared/TableComponents';
import Loader from '#/shared/Loader';
import Error from '#/shared/Error';
import Button from '#/shared/Button';
import palette from '#/theme/palettes/main';
import { UseIngestionsHook } from './useFetchIngestions';
import Ingestion from '#/types/Ingestion';
import IngestionIcons from './IngestionIcons';
import { formatGtv } from '#/shared/formatNumberForDisplay';
import DownArrow from '#images/DownArrow.svg';
import UpArrow from '#images/UpArrow.svg';
import UpDownArrow from '#images/UpDownArrow.svg';
import Star from '#images/Star.svg';
import FullStar from '#images/FullStar.svg';
import { StakeholderLogos } from '../useFetchStakeholders';

const ERROR_COPY = 'Something went wrong retrieving ingested listings!';

interface Column {
  label: string;
  colSpan: number;
  sortValue?: string;
}

const COLUMNS: Column[] = [
  { label: 'ID', colSpan: 1, sortValue: 'id' },
  { label: 'Event', colSpan: 1, sortValue: 'event_starts_at' },
  { label: 'Section', colSpan: 1, sortValue: 'section' },
  { label: 'Row', colSpan: 1, sortValue: 'row' },
  { label: 'Seats', colSpan: 1, sortValue: 'start_seat' },
  { label: 'Qty', colSpan: 1, sortValue: 'quantity' },
  { label: 'Cost', colSpan: 1, sortValue: 'cost_per_ticket' },
  { label: 'FV', colSpan: 1, sortValue: 'face_value_per_ticket' },
  { label: 'GTV', colSpan: 1 },
  { label: 'Stakeholder', colSpan: 1, sortValue: 'stakeholder' },
  { label: 'Properties', colSpan: 1 },
  { label: 'Created At', colSpan: 1, sortValue: 'created_at' },
  { label: 'Pin', colSpan: 1 },
];
const PREVIOUS_PAGE = 'Previous Page';
const NEXT_PAGE = 'Next Page';

interface IngestionsTableProps {
  selectedIds: number[];
  setSelected: React.Dispatch<React.SetStateAction<Ingestion[]>>;
  ingestionsHook: UseIngestionsHook;
  stakeholderLogos: StakeholderLogos;
  pinnedIngestions: Ingestion[];
  setPinnedIngestions: (value: React.SetStateAction<Ingestion[]>) => void;
}

const IngestionsTable: React.FC<IngestionsTableProps> = ({
  selectedIds,
  setSelected,
  ingestionsHook,
  stakeholderLogos,
  pinnedIngestions,
  setPinnedIngestions,
}) => {
  const {
    ingestions,
    perPage,
    filters,
    setFilters,
    retry,
    pages,
    prevPage,
    nextPage,
    showResults,
    showError,
    showLoader,
  } = ingestionsHook;
  const onClick = useCallback((event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = parseInt((event.currentTarget.children[0] as HTMLTableCellElement).innerText, 10);
    const selected = ingestions.find((ingestion) => ingestion.id === id);

    if (selected) {
      // support for determining which rows to add as selected, supporting conventional
      // multi-row-select behavior.
      if (event.ctrlKey || event.metaKey) {
        const alreadySelected = selectedIds.includes(id);

        if (!alreadySelected)
          setSelected((s) => [...s, selected]);
      } else if (event.shiftKey) {
        const lastSelected = selectedIds[selectedIds.length - 1];

        if (lastSelected) {
          const lastIdx = ingestions.findIndex((ingestion) => ingestion.id === lastSelected);
          const curIdx = ingestions.findIndex((ingestion) => ingestion.id === id);
          const start = Math.min(lastIdx, curIdx);
          const end = Math.max(lastIdx, curIdx);
          const newSelected = ingestions
            .filter((ingestion: Ingestion, idx: number): boolean => {
              return (
                idx >= start
                && idx <= end
                && !selectedIds.includes(ingestion.id)
              );
            });

          if (newSelected.length > 0)
            setSelected((s) => [...s, ...newSelected]);
        }
      } else {
        setSelected([selected]);
      }
    }
  }, [setSelected, ingestions, selectedIds]);

  const switchOrder = useCallback(() => {
    const sort_asc = 1 - (filters.sort_asc ?? 0);

    setFilters({ ...filters, sort_asc });
  }, [setFilters, filters]);

  const setSortByColumn = useCallback((column: Column) => {
    if (column.sortValue)
      setFilters({ ...filters, sort_by: column.sortValue, sort_asc: 1 });
  }, [setFilters, filters]);
  const headers = useMemo(() => {
    return COLUMNS.map((column) => {
      if (column.sortValue && column.sortValue === filters.sort_by) {
        return (
          <NoWrapCell key={`sort_cell_${column.sortValue}`} onClick={switchOrder} role="columnheader" style={{ cursor: 'pointer' }}>
            <OffsetImage
              alt="sort"
              height="13"
              src={filters.sort_asc ? UpArrow : DownArrow}
              title={filters.sort_asc ? 'Ascending' : 'Descending'}
            />
            {column.label}
          </NoWrapCell>
        );
      }
      if (column.sortValue && column.sortValue !== filters.sort_by) {
        return (
          <NoWrapCell
            colSpan={column.colSpan}
            key={column.label}
            // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
            onClick={(): void => (setSortByColumn(column))}
            role="columnheader"
            style={{ cursor: column.sortValue ? 'pointer' : null }}
          >
            <OffsetImage
              alt="sort"
              height="13"
              src={UpDownArrow}
              style={{ opacity: 0.4 }}
              title="Sortable"
            />
            {column.label}
          </NoWrapCell>
        );
      }
      return (
        <Cell
          colSpan={column.colSpan}
          key={column.label}
          // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
          onClick={(): void => (setSortByColumn(column))}
          role="columnheader"
          style={{ cursor: column.sortValue ? 'pointer' : null }}
        >
          {column.label}
        </Cell>
      );
    });
  }, [filters.sort_by, filters.sort_asc, switchOrder, setSortByColumn]);

  const pinnedIds = useMemo(() => pinnedIngestions.map((s) => s.id), [pinnedIngestions]);

  const setPinned = useCallback((ingestion: Ingestion) => {
    const pinned = ingestions.find((i) => i.id === ingestion.id);

    if (pinned) {
      // support for determining which rows to add as pinned
      const alreadyPinned = pinnedIds.includes(ingestion.id);

      if (alreadyPinned)
        setPinnedIngestions((p) => p.filter((c) => c.id !== pinned.id));
      else
        setPinnedIngestions((p) => [...p, pinned]);
    }
  }, [setPinnedIngestions, ingestions, pinnedIds]);

  return (
    <>
      <TableWrapper title='ingestions-table'>
        <thead>
          <Header>
            {headers}
          </Header>
        </thead>
        {showResults && (
          <tbody>
            {pages.length > 0 && (
              <RowWrapper key="load_previous_rows">
                <td colSpan={COLUMNS.map((column) => column.colSpan).reduce((a, b) => a + b)}>
                  <ButtonWrapper>
                    <Button onClick={prevPage}>{PREVIOUS_PAGE}</Button>
                  </ButtonWrapper>
                </td>
              </RowWrapper>
            )}
            {ingestions.map((ingestion) => (
              <IngestionsTableRow
                ingestion={ingestion}
                isPinned={pinnedIds.some((id) => id === ingestion.id)}
                isSelected={selectedIds.some((id) => id === ingestion.id)}
                key={ingestion.id}
                onClick={onClick}
                setPinned={setPinned}
                stakeholderLogos={stakeholderLogos}
              />
            ))}
            {ingestions.length === perPage && (
              <RowWrapper key="load_next_rows">
                <td colSpan={COLUMNS.map((column) => column.colSpan).reduce((a, b) => a + b)}>
                  <ButtonWrapper>
                    <Button onClick={nextPage}>{NEXT_PAGE}</Button>
                  </ButtonWrapper>
                </td>
              </RowWrapper>
            )}
          </tbody>
        )}
      </TableWrapper>
      {showError && (
        <CenterContainer>
          <Error copy={ERROR_COPY} retry={retry} />
        </CenterContainer>
      )}
      {showLoader && (
        <CenterContainer>
          <Loader hexColor={palette.brand.base} />
        </CenterContainer>
      )}
    </>
  );
};

interface IngestionsTableRowProps {
  ingestion: Ingestion;
  stakeholderLogos: StakeholderLogos;
  isSelected: boolean;
  isPinned: boolean;
  onClick: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  setPinned: (ingestion: Ingestion) => void;
}

const IngestionsTableRow: React.FC<IngestionsTableRowProps> = ({
  ingestion,
  stakeholderLogos,
  isSelected,
  isPinned,
  onClick,
  setPinned,
}) => {
  const {
    id,
    candidate_event,
    seatgeek_event_id,
    uploader_event_id,
    section,
    row,
    start_seat,
    end_seat,
    quantity,
    cost,
    face_value,
    gtv,
    stakeholder,
    deal,
    deal_term,
    created_at,
  } = ingestion;

  const stakeholderLogo = stakeholderLogos[stakeholder];

  const seatRange = `${start_seat}-${end_seat}`;

  const backgroundColor = isSelected ? palette.brand.light24 : null;

  let event = `${seatgeek_event_id} • ${uploader_event_id}`;
  const stakeholder_field = stakeholder + (deal ? `\n${deal}` : '') + (deal_term ? `\n${deal_term}` : '');

  if (candidate_event) {
    const { title, venue, event_starts_at_local } = candidate_event;

    event += ` • ${event_starts_at_local}\n${title.split('\t').join('')} • ${venue.split('\n').join(' • ')}`;
  }

  const onPinClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    // stop the row onClick from being called
    e.stopPropagation();
    setPinned(ingestion);
  }, [ingestion, setPinned]);

  return (
    <ClickableRowWrapper color={backgroundColor} key={`${id}`} onClick={onClick}>
      <Cell key={`id_${id}`}>{id}</Cell>
      <Cell key={`event_${id}`} style={{ whiteSpace: 'pre', maxWidth: '16rem' }} title={event}>
        {event}
      </Cell>
      <SmallCell key={`section_${id}`} title={section}>{section}</SmallCell>
      <SmallCell key={`row_${id}`} title={row}>{row}</SmallCell>
      <SmallCell key={`seats_${id}`} title={seatRange}>{seatRange}</SmallCell>
      <Cell key={`quantity_${id}`}>{quantity}</Cell>
      <Cell key={`cost_${id}`}>{formatGtv(cost)}</Cell>
      <Cell key={`face_value_${id}`}>{face_value ? formatGtv(face_value) : ''}</Cell>
      <Cell key={`gtv_${id}`}>{formatGtv(gtv)}</Cell>
      <Cell key={`stakeholder_${id}`} style={{ whiteSpace: 'pre', maxWidth: '7rem' }} title={stakeholder_field}>
        {stakeholder_field}
      </Cell>
      <NoWrapCell key={`properties_${id}`}>
        <IngestionIcons ingestion={ingestion} stakeholderLogo={stakeholderLogo} />
      </NoWrapCell>
      <Cell key={`created_at_${id}`} style={{ whiteSpace: 'pre' }}>
        {created_at.toRelative() + '\n' + created_at.toLocaleString(DateTime.DATETIME_SHORT)}
      </Cell>
      <SmallCell>
        <RowButton onClick={onPinClick} title="pin listing">
          {isPinned ? <FullStarImg id="pinned" /> : <StarImg id="unpinned" />}
        </RowButton>
      </SmallCell>
    </ClickableRowWrapper>
  );
};

const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  text-align:center;
  width:95%;
`;

const OffsetImage = styled.img`
  padding-right: 2px;
`;

const StarImg = styled.img`
  content: url(${Star});
  height: 20px;
`;

const FullStarImg = styled.img`
  content: url(${FullStar});
  height: 20px;
`;

const RowButton = styled.div`
  cursor: pointer;
  padding-right: 0.75rem;
`;

export default IngestionsTable;
